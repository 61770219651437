<template>
        <VueSlickCarousel v-bind="settings2" v-if="!mobileView">
              <div>
              <div class="testimonial-item">
                <div class="stars">
                  <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                </div>
                <p>
                Estos pibes son lo más, no sabes, siempre arreglan cuando se rompe algo
                </p>
                <div class="profile mt-auto">
                  <img src="assets/img/testimonials/testimonials-1.webp" class="testimonial-img" alt="">
                  <h3>Ricardo J.</h3>
                  <h4>Admin TI</h4>
                </div>
              </div>
              </div>
              <div>
              <div class="testimonial-item">
                <div class="stars">
                  <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                </div>
                <p>
                Esta gente tiene buena onda me ayudaron mucho
                </p>
                <div class="profile mt-auto">
                  <img src="assets/img/testimonials/testimonials-2.webp" class="testimonial-img" alt="">
                  <h3>Ezequiel M.</h3>
                  <h4>Admin TI</h4>
                </div>
              </div>
              </div>
              <div>
              <div class="testimonial-item">
                <div class="stars">
                  <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                </div>
                <p>
                El pibito rubio es bien gauchito, sabe lo que hace
                </p>
                <div class="profile mt-auto">
                  <img src="assets/img/testimonials/testimonials-3.webp" class="testimonial-img" alt="">
                  <h3>Jorge M.</h3>
                  <h4>Admin TI</h4>
                </div>
              </div>
              </div>
              <div>
              <div class="testimonial-item" >
                <div class="stars">
                  <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                </div>
                <p>
                No los conozco pero seguro son buena gente
                </p>
                <div class="profile mt-auto">
                  <img src="assets/img/testimonials/testimonials-4.webp" class="testimonial-img" alt="">
                  <h3>Eduardo R.</h3>
                  <h4>Admin TI</h4>
                </div>
              </div>
              </div>
              <div>
              <div class="testimonial-item">
                <div class="stars">
                  <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                </div>
                <p>
                Se portan mejor que mis hijos, un lujo
                </p>
                <div class="profile mt-auto">
                  <img src="assets/img/testimonials/testimonials-5.webp" class="testimonial-img" alt="">
                  <h3>Laura M.</h3>
                  <h4>CEO - La mejor</h4>
                </div>
              </div>
              </div>
        </VueSlickCarousel>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  components: { VueSlickCarousel },
  data () {
    return {
      settings2: {
        dots: true,
        focusOnSelect: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 2,
        touchThreshold: 5,
        autoplay: true,
        autoplaySpeed: 3500
      },
      mobileView: true
    }
  },
  methods: {
    handleView () {
      this.mobileView = window.innerWidth <= 990
    }
  },
  created () {
    this.handleView()
    window.addEventListener('resize', this.handleView)
  }
}
</script>

<style>

</style>
